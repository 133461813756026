export default {
    methods: {
        getDataDefensor(){
            if (!this.$store.state.abogado.esDefensorRuanna) {
            if(this.$vuetify.breakpoint.xs){
                this.$store.commit('setDrawer',false)
            }
            this.$store.commit('setMessageOverlay','Verificando datos de RUANNA...')
            this.$store.commit('setOverlay',true);
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/defensor?tipo=${this.tipoDefensa.value}`)
            .then(response=>{
                if(response.data.data!=null){
                    this.esDefensorRuanna=true
                    this.defensor = response.data.data
                }else{
                    this.esDefensorRuanna=false
                }
                console.log('------------------------------------');
                console.log(this.defensor);
                console.log('------------------------------------');
               }).catch(error=>{
                console.log('------------------------------------');
                console.log(error);
                console.log('------------------------------------');
                this.$store.commit('setIsRegistered', false);
                this.$router.push({name:'Home'});
               }).finally(()=>{
                this.$store.commit('setOverlay',false);
            });
        }
        }
    }
}