<template>
  <component 
    :is="esDefensorRuanna?'Defensor':'NuevoRuanna'"
    :defensor="defensor"
  />
</template>
<script>
import MixinDefensor from '@/mixins/defensor'
export default {
  components:{
    'Defensor':()=>import('./Defensor'),
    'NuevoRuanna':()=>import('./NuevoRuanna'),
  },
  data(){
    return{
      tipoDefensa: this.$store.getters.getParameter("tipo_defensor", "RUANNA"),
      esDefensorRuanna:null,
      defensor:null
    }
  },
  mixins:[MixinDefensor],
  async created(){
    await this.getDataDefensor();
  }
}
</script>